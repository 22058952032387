/* eslint-disable indent */
export default function (val, mask, rev = false) {
  if (rev) {
    val = val
      .split('')
      .reverse()
      .join('')
    mask = mask
      .split('')
      .reverse()
      .join('')
  }
  let maskared = ''
  let k = 0
  for (let i = 0; i <= mask.length - 1; i++) {
    if (mask[i] === '#') {
      if (val[k]) maskared = `${maskared}${val[k++]}`
    } else {
      if (mask[i]) maskared = `${maskared}${mask[i]}`
    }
  }
  return rev
    ? maskared
        .split('')
        .reverse()
        .join('')
    : maskared
}
